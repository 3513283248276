import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Layout from '../../components/layout'
import Header from '../../components/header'
import Container from '../../components/container'
import Row from '../../components/row'
import Column from '../../components/column'
import StoryIntro from '../../components/story-intro'

SwiperCore.use([Navigation, Pagination, Autoplay])

const PageHeader = <Header title="Die Wallbox" />

const WallboxPage = ({ location }) => {
    const images = useStaticQuery(graphql`
      {
        image: allFile(filter: {relativeDirectory: {eq: "stories/wallbox"}, name: {eq: "DSC07715"}}) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                width: 510
                transformOptions: {
                  cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            name
          }
        }
        slider: allFile(filter: {relativeDirectory: {eq: "stories/wallbox"}, name: {ne: "DSC07715"}}, sort: {fields: name}) {
            nodes {
              childImageSharp {
                gatsbyImageData(
                  width: 510
                  height: 380
                  transformOptions: {
                    cropFocus: CENTER
                  }
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
              name
            }
        }
      }
    `)

    return (
        <Layout translatedPageLinks={[{ code: 'de', link: '/de/story/wallbox/' }, { code: 'en', link: '/en/story/ev-charger/' }]} pageLang="de" pageTitle="Die Wallbox" pageHeader={PageHeader} location={location}>
            <Container>
                <section>
                    <StoryIntro>
                        <p>
                            Die Elektromobilität boomt. Der Ausbau der privaten und öffentlichen Ladeinfrastruktur kann aktuell kaum Schritt halten.
                            Dadurch ergeben sich auch für GE-T neue Herausforderungen.
                            Nicht nur bei der Entwicklung innovativer Wallboxen, sondern auch bei der Etablierung nachhaltiger Strukturen eines optimal funktionierenden Systems aus Hard- und Software für das notwendige Lade-, Last- und Abrechnungs&shy;management.{` `}
                            {/* eslint-disable-next-line */}
                            Im Zentrum steht dabei die von GE-T für die <a href="https://www.cubos.com/" target="_blank">CUBOS GmbH</a> entwickelte C11E-Wallbox in den Varianten Premium, Smart und Go.
                        </p>
                    </StoryIntro>
                    <Row>
                        <Column columnWidth={2}>
                            <p>
                                CUBOS ist als Spezialist für Ladeinfrastruktur die jüngste Firma der Wolfsburger ES-Tec Gruppe.
                                Sie hat seit Markteinführung der Elli ID.Charger – einer Marke des Volkswagen Konzerns – im Jahr 2020 bereits mehr als 1.000 Ladepunkte installiert.
                                Bisher überwiegend in Privathaushalten im Stadtgebiet und der Metropolregion Wolfsburg.
                            </p>
                            <p>
                                Dank GE-T wird CUBOS mit der C11E-Wallbox in der Lage sein, sowohl B2C-, als auch B2B-Kunden sämtlicher Größenordnungen zu bedienen.
                                Der anstehende Meilenstein des Projektes ist die Elektrifizierung eines Parkhauses in der Umgebung von Wolfsburg mit rund 100 Ladepunkten.
                                Im Fokus befindet sich dabei das AC-Laden im Bereich von 11 bis 22 kW während eines längeren Aufenthaltes – wie zum Beispiel der Arbeitszeit.
                            </p>
                        </Column>
                        <Column columnWidth={2}>
                            {<GatsbyImage image={getImage(images.image.nodes[0])} alt="" />}
                        </Column>
                    </Row>
                    <br />
                    <Row>
                        <Column columnWidth={2}>
                            <Swiper
                                navigation
                                loop
                                autoplay
                                pagination={{ clickable: true }}
                            >
                                {/* eslint-disable-next-line */}
                                {images.slider.nodes.map((image) => <SwiperSlide key={image.name}><GatsbyImage image={getImage(image)} alt="" /></SwiperSlide>)}
                            </Swiper>
                        </Column>
                        <Column columnWidth={2}>
                            <p>
                                Insbesondere die exakte Abrechnung der jeweils geladenen Strommenge ist eine Herausforderung für jede Eigentümergemeinschaft und jedes Unternehmen – absolut unabhängig von seiner Größe.
                                CUBOS wird mit Hilfe der innovativen Vorarbeit von GE-T den Betrieb sämtlicher Ladepunkte eines Kunden übernehmen können.
                                Nur ein Clou dabei: NFC- beziehungsweise RFID-Technik gewährleistet die individuelle Abrechnung der sowohl bei der Arbeit, als auch im privaten Haushalt geladenen Strommenge in einem Vorgang, wenn beide von GE-T entwickelte Ladepunkte von CUBOS betrieben werden.
                            </p>
                        </Column>
                    </Row>
                </section>
            </Container>
        </Layout>
    )
}

export default WallboxPage